// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("/opt/build/repo/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-offer-js": () => import("/opt/build/repo/src/templates/offer.js" /* webpackChunkName: "component---src-templates-offer-js" */),
  "component---src-templates-news-js": () => import("/opt/build/repo/src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-service-js": () => import("/opt/build/repo/src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-location-js": () => import("/opt/build/repo/src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-pages-request-js": () => import("/opt/build/repo/src/pages/request.js" /* webpackChunkName: "component---src-pages-request-js" */)
}

