import { makeStyles } from '@material-ui/core/styles'
import { Box } from 'components'
import Img from 'gatsby-image'
import React from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    alignItems: 'center',
    marginBottom: 50
  },
  photo: {
    width: 350
  },
  name: {
    fontWeight: 'bold',
    fontSize: 25,
    margin: 5
  },
  role: {
    margin: 5,
    fontSize: 25,
    textAlign: 'center'
  }
}))

function TeamMember (props) {
  const { member } = props


  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Img className={classes.photo} fluid={member.photo.fluid} />
      <p className={classes.name}>{member.name}</p>
      <p className={classes.role}>{member.role}</p>
    </Box>
  )
}

export default TeamMember
