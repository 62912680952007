import Container from '@material-ui/core/Container'
import { withStyles } from '@material-ui/core/styles'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { Box } from 'components'
import { graphql, useStaticQuery } from 'gatsby'
import useFilterLocale from 'hooks/useFilterLocale'
import React, { useCallback, useMemo, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { mod } from 'react-swipeable-views-core'
import { bindKeyboard, virtualize } from 'react-swipeable-views-utils'

import { ServicesSliderCard } from '../ServicePreviewCard'

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews))

const getServices = graphql`
  {
    allContentfulService {
      edges {
        node {
          id
          slug
          node_locale
          serviceName
          coverPhoto {
            fluid {
              ...GatsbyContentfulFluid
            }
            title
          }
          description {
            json
          }
          photo {
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyContentfulFluid
            }
            title
          }
        }
      }
    }
  }
`

const styles = {
  root: {
    paddingRight: 'calc(100% - 350px)',
    marginTop: '30px'
  },
  slideContainer: {
    // padding: '0 10px'
  },
  slide: {
    minHeight: 100
  },
  arrowContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    fontSize: 70,
    cursor: 'pointer',
    backgroundColor: '#71cc98',
    '&:hover': {
      color: '#fff'
    }
  }
}

function makeSlideRenderer (services, activeIndex) {
  return function slideRenderer (params) {
    const { index, key } = params
    const idx = mod(index, services.length)

    return (
      <Box key={key}>
        <ServicesSliderCard
          noMargin
          index={idx + 1}
          noOverlay={activeIndex === index}
          service={services[idx]}
        />
      </Box>
    )
  }
}

function DemoVirtualize (props) {
  const data = useStaticQuery(getServices)
  const [index, setIndex] = useState(0)
  const handleNext = useCallback(() => setIndex(idx => idx + 1), [])
  const handlePrev = useCallback(() => setIndex(idx => idx - 1), [])
  const handleChangeIndex = useCallback(idx => setIndex(idx), [])

  const slides = useMemo(
    () => data.allContentfulService.edges.map(edge => edge.node),
    [data.allContentfulService.edges]
  )
  const filteredSlides = useFilterLocale(slides)
  const slideRenderer = useMemo(
    () => makeSlideRenderer(filteredSlides, index),
    [filteredSlides, index]
  )

  return (
    <Container maxWidth="xl">
      <VirtualizeSwipeableViews
        enableMouseEvents
        index={index}
        overscanSlideAfter={5}
        overscanSlideBefore={2}
        slideRenderer={slideRenderer}
        slideStyle={styles.slideContainer}
        style={styles.root}
        onChangeIndex={handleChangeIndex}
      />
      <Box className={props.classes.arrowContainer}>
        <KeyboardArrowLeft className={props.classes.icon} onClick={handlePrev} />
        <KeyboardArrowRight className={props.classes.icon} onClick={handleNext} />
      </Box>
    </Container>
  )
}

export default withStyles(styles)(DemoVirtualize)
