import {
  FleetSlider,
  LatestNews,
  LocationsSlider,
  LocationsTicker,
  NewsTicker,
  OffersTicker,
  ServicesSlider,
  ServicesTicker,
  TeamMembersTicker,
  TestimonialsSlider} from "components";
import React from "react";

function Ticker (props) {
  const { contentType, variant, amount } = props
  switch (contentType) {
    case 'news': {
      return <NewsTicker amount={amount} />
    }
    case 'offers': {
      return <OffersTicker amount={amount} />
    }
    case 'services': {
      if (variant === 'slider') return <ServicesSlider amount={amount} />
      return <ServicesTicker amount={amount} />
    }
    case 'locations': {
      if (variant === 'slider') return <LocationsSlider amount={amount} />
      return <LocationsTicker amount={amount} />
    }
    case 'testimonials': {
      return <TestimonialsSlider amount={amount} />
    }
    case 'latestnews': {
      return <LatestNews amount={amount} />
    }
    case 'fleet': {
      return <FleetSlider amount={amount} />
    }

    case 'teammember': {
      return <TeamMembersTicker amount={amount} />
    }
    default:
      return `No ticker available for contentType '${contentType}'`
  }
}

export default Ticker
