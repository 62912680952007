import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback, useMemo, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { mod } from 'react-swipeable-views-core'
import { bindKeyboard, virtualize } from 'react-swipeable-views-utils'

import Slide from './Slide'

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews))

const useStyles = makeStyles(theme => ({
  container: {
    '& div': {
      overflowX: 'visible'
    }
  },
  root: {
    overflowX: 'visible',
    [theme.breakpoints.up('sm')]: {
      padding: '0 15%'
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 25%'
    }
  },
  slideContainer: {
    padding: '0 32px'
  }
}))

function makeSlideRenderer (vehicles, activeIndex) {
  return function slideRenderer (params) {
    const { index, key } = params
    const idx = mod(index, vehicles.length)
    const activeIdx = mod(activeIndex, vehicles.length)

    return (
      <Slide
        key={key}
        activeIndex={activeIdx}
        currentIndex={idx}
        vehicle={vehicles[idx]}
      />
    )
  }
}

export function useSliderIndex (len) {
  const [index, setIndex] = useState(0)
  const handleNext = useCallback(() => setIndex(idx => idx + 1), [])
  const handlePrev = useCallback(() => setIndex(idx => idx - 1), [])
  const handleChangeIndex = useCallback(idx => setIndex(idx), [])

  return useMemo(
    () => ({
      props: {
        index,
        handleChangeIndex
      },
      next: handleNext,
      prev: handlePrev,
      activeIndex: mod(index, len)
    }),
    [handleChangeIndex, handleNext, handlePrev, index, len]
  )
}

function Slider (props) {
  const { slides, index, handleChangeIndex } = props
  const classes = useStyles()
  const slideRenderer = useMemo(() => makeSlideRenderer(slides, index), [
    slides,
    index
  ])

  return (

    <VirtualizeSwipeableViews
      enableMouseEvents
      className={classes.root}
      index={index}
      slideClassName={classes.slideContainer}
      slideRenderer={slideRenderer}
      onChangeIndex={handleChangeIndex}
    />

  )
}

export default Slider
