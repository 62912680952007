import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { graphql, useStaticQuery } from "gatsby";
import useFilterLocale from "hooks/useFilterLocale";
import React, { useMemo } from "react";

import OfferPreviewCard from "../OfferPreviewCard";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: 0
  }
});

const getOffers = graphql`
  {
    allContentfulOfferGroup {
      edges {
        node {
          id
          node_locale
          title
          shortDescription
          slug
          coverPhoto {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;

function OffersTicker(props) {
  const classes = useStyles();
  const { amount } = props;
  const data = useStaticQuery(getOffers);
  const offerGroups = useMemo(
    () => data.allContentfulOfferGroup.edges.map(edge => edge.node),
    [data.allContentfulOfferGroup.edges]
  );
  const filteredOfferGroups = useFilterLocale(offerGroups);

  return (
    <Container className={classes.container} maxWidth="lg">
      {filteredOfferGroups.slice(0, amount).map(offer => (
        <OfferPreviewCard key={offer.id} offer={offer} />
      ))}
    </Container>
  );
}

export default OffersTicker;
