import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "gatsby";
import Img from "gatsby-image";
import React, { useMemo } from "react";
import styled from "styled-components";

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const useStyles = makeStyles({
  card: {
    maxWidth: 365,
    margin: 20
  },
  media: {
    height: "100px"
  },
  heading: {
    fontFamily: "Libre Baskerville",
    fontWeight: "bold",
    margin: "15px 0",
    fontSize: "26px",
    fontWeight: "bold",
    lineHeight: "33.99px"
  },
  body2: {
    color: "#000"
  },
  excerpt: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "25px"
  },
  author: {
    color: "#918e8b",
    fontFamily: "Baskerville",
    fontSize: "20px",
    fontStyle: "italic",
    fontWeight: "bold"
  },
  link: {
    textDecoration: "none",
    fontSize: "16px",
    color: "#71cc98;"
  }
});

function NewsCard(props) {
  const { news } = props;
  const classes = useStyles();


  const excerpt = useMemo(
    () =>
      documentToPlainTextString(news.newsContent.json)
        .slice(0, 255)
        .replace(/\s\w+$/, ""),
    [news.newsContent.json]
  );

  return (
    <StyledLink to={news.to}>
      <Card className={classes.card}>
        <CardActionArea>
          <CardMedia>
            <Img fixed={news.coverPhoto.fixed} />
          </CardMedia>
          <CardContent>
            <Typography
              gutterBottom
              className={classes.heading}
              component="h2"
              variant="h5"
            >
              {news.title}
            </Typography>
            <Typography
              className={classes.excerpt}
              color="textSecondary"
              component="p"
              variant="body2"
            >
              {excerpt}&hellip;
            </Typography>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
            >
              <p className={classes.author}>By: {news.author}</p>
              <Link className={classes.link} to={news.slug}>
                Read more &#8594;
              </Link>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </StyledLink>
  );
}

export default NewsCard;
