import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'
import useNavigationLinks from 'hooks/useNavigationLinks'
import React, { useState } from 'react'
import styled from 'styled-components'

import LanguageSwitcher from '../LanguageSwitcher'

const NavBar = styled.nav`
  display: flex;
  justify-content: center;
  position: relative;
  @media (max-width: 1300px) {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`

const List = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1300px) {
    ${({ open }) => (open ? '' : 'display: none;')}
    flex-direction: column;
    width: 100%;
    justify-content: center;
    position: absolute;
    z-index: 2000;
    background-color: black;
    text-align: center;
    margin: 0;
    padding: 0;
  }
`
const ListItem = styled.li`
  color: white;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
  padding: 10px 15px;
  font-family: Barlow;

  @media (max-width: 1300px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    padding: 5px;
  }
`

const LanguagesContainer = styled(ListItem)`
  padding: 0px;
  display: flex;
  justify-content: center;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`
const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  font-size: 3rem;
  right: 20px;
  color: #71cc98;
  margin-bottom: 20px;
  margin-right: 20px;
  @media (min-width: 1301px) {
    display: none;
  }
  @media (max-width: 600px) {
    font-size: 2.2rem;
  }
`

function Navigation () {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)

  const links = useNavigationLinks()

  const ToggleMobileNav = () => {
    setIsMobileNavOpen(prevIsMobileNavOpen => !prevIsMobileNavOpen)
  }

  return (
    <>
      {isMobileNavOpen ? (
        <Icon icon={faTimes} onClick={ToggleMobileNav} />
      ) : (
        <Icon icon={faBars} onClick={ToggleMobileNav} />
      )}
      <NavBar>
        <List ToggleMobileNav={ToggleMobileNav} open={isMobileNavOpen}>
          {links.map(link => (
            <StyledLink key={link.id} to={link.to} onClick={ToggleMobileNav}>
              <ListItem>{link.title}</ListItem>
            </StyledLink>
          ))}
          <LanguagesContainer onClick={ToggleMobileNav}>
            <LanguageSwitcher />
          </LanguagesContainer>
        </List>
      </NavBar>
    </>
  )
}

export default Navigation
