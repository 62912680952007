import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import styled from "styled-components";

import BodyRenderer from "../BodyRenderer";

const Image = styled(Img)`
  width: 100%;
  height: 80vh;
  margin: 0 auto;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;
const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    width: "100%",
    margin: "0 auto",
    marginBottom: "100px"
  },
  imageContainer: {
    position: "relative"
  },
  headingContainer: {
    position: "absolute",
    top: "30%",
    backgroundColor: "black",
    zIndex: "200"
  },

  heading: {
    color: "white",
    zIndex: "200",
    textAlign: "right",
    margin: 15,
    fontSize: 35,
    letterSpacing: "4px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      letterSpacing: "2px"
      // lineHeight: '60px'
    }
  },
  mainHeading: {
    textTransform: "uppercase",
    fontSize: 50,
    fontWeight: "bold",
    textAlign: "center",
    margin: 20,
    fontFamily: "Baskerville",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px"
      // lineHeight: '60px'
    }
  },

  description: {
    fontFamily: "Baskerville",
    fontSize: 22,
    borderRight: "2px solid black",
    padding: "0px 50px",
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none"
    },
    [theme.breakpoints.down("md")]: {
      borderRight: "none",
      borderBottom: "2px solid black",
      padding: "0px 20px"
    }
  },
  price: {
    fontFamily: "Baskerville",
    fontWeight: "bold",
    fontSize: 30,
    textTransform: "capitalize",
    margin: "20px"
  },
  details: {
    fontSize: 20,
    margin: "20px",
    "& p": { margin: 0 }
  },

  detailsrow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  button: {
    color: "#fff",
    width: "200px",
    height: "55px",
    textTransform: "uppercase",
    fontSize: "19px",
    fontWeight: 700,
    margin: "20px",
    borderRadius: "0px",
    boxShadow: "none"
  }
}));

function Offer(props) {
  const classes = useStyles();
  const { offer } = props;
  console.log(offer)
  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.imageContainer} sm={12}>
        <Box className={classes.headingContainer}>
          <Typography paragraph className={classes.heading}>
            {offer.title}
          </Typography>
        </Box>
        {/* {console.log(offer)} */}
        <Image fluid={offer.coverPhoto.fluid} />
      </Grid>

      <Grid container justify="center" sm={12}>
        <Grid item sm={10} xl={8}>
          <Typography className={classes.mainHeading} variant="h4">
            {offer.mainHeading}
          </Typography>
        </Grid>
      </Grid>

      <Grid container justify="center" sm={12} spacing={4}>
        <Grid item lg={6} md={12} sm={12} xl={4}>
          <Box className={classes.description}>
            <BodyRenderer json={offer.description?.json} />
          </Box>
        </Grid>

        <Grid item className={classes.detailsrow} md={6} sm={12} xl={4}>
          <Box>
            <Typography className={classes.price} variant="h4">
              {offer.tourPrice}
            </Typography>
            <div className={classes.details}>
              <BodyRenderer json={offer.tourDetails?.json} />
            </div>
          </Box>
          <StyledLink to={'/'+ offer.node_locale + '/' + offer.buttonLink?.slug}>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
            >
              {offer.buttonLabel}
            </Button>
          </StyledLink>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Offer;
