import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { graphql, Link, useStaticQuery } from "gatsby";
import useSubFooterLinks from "hooks/useSubFooterLinks";
import React, { useMemo } from "react";
import styled from "styled-components";
import Certifications from "./Certifications";
const getSubFooterContent = graphql`
  {
    contentfulFooter {
      copyrightText
      subfooterPagesLinks {
        ... on ContentfulPage {
          title
          slug
          id
        }
      }
    }

    contentfulLayout {
      socialMediaIconLinks {
        ... on ContentfulSocialMediaIcon {
          id
          label
          url
          icon {
            file {
              url
            }
          }
        }
      }
    }
    contentfulLayout {
      certificationPhotos {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin: 10px;
`;
const IconsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    display: "flex",
    backgroundColor: "#010101", //#252525;

    color: "white",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start"
    }
  },
  pageLinks: {
    display: "flex",
    listStyleType: "none",
    flexWrap: "wrap",
    "& p": {
      margin: 0,
      fontSize: 14
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: 0
    }
  },
  link: {
    textDecoration: "none",
    color: "#fff",
    margin: "0px 20px",
    fontSize: 18
  },
  divider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#fff",
    margin: 10
  },
  socialMedia: {
    display: "flex",
    justifyContent: "center"
  },
  copyright: {
    display: "flex",
    "& p": {
      fontSize: "14px"
    }
  }
}));

function SubFooter() {
  const data = useStaticQuery(getSubFooterContent);
  const classes = useStyles();
  const links = useSubFooterLinks();
  const iconsList = data.contentfulLayout.socialMediaIconLinks;

  return (
    <>
      <Box className={classes.container}>
        <Certifications />
        <Box className={classes.divider} />
      </Box>

      <Box className={classes.container}>
        <Box>
          <ul className={classes.pageLinks}>
            {links.map(link => (
              <Link key={link.id} className={classes.link} to={link.to}>
                <p style={{ fontSize: 18, fontWeight: 400 }}> {link.title}</p>
              </Link>
            ))}
          </ul>
        </Box>

        <Box>
          <IconsContainer>
            {iconsList.map(icon => (
              <div key={icon.id}>
                <a href={icon.url}>
                  <Icon src={icon.icon.file.url} />
                </a>
              </div>
            ))}
          </IconsContainer>
        </Box>
        <Box className={classes.copyright} style={{ paddingLeft: 20 }}>
          <p>{data.contentfulFooter.copyrightText}</p>
        </Box>
      </Box>
    </>
  );
}

export default SubFooter;
