import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { graphql, useStaticQuery } from "gatsby";
import useFilterLocale from "hooks/useFilterLocale";
import React, { useMemo } from "react";

import { ServicePreviewCard } from "../ServicePreviewCard";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  }
});

const getServices = graphql`
  {
    allContentfulService {
      edges {
        node {
          id
          slug
          node_locale
          serviceName
          coverPhoto {
            fluid {
              ...GatsbyContentfulFluid
            }
            title
          }
          description {
            json
          }
          photo {
            fluid(maxHeight: 500, quality: 70) {
              ...GatsbyContentfulFluid
            }
            title
          }
        }
      }
    }
  }
`;

function ServicesTicker(props) {
  const classes = useStyles();
  const { amount } = props;
  const data = useStaticQuery(getServices);

  const services = useMemo(
    () => data.allContentfulService.edges.map(edge => edge.node),
    [data.allContentfulService.edges]
  );
  const filteredServices = useFilterLocale(services);

  return (
    <Container className={classes.container} maxWidth="xl">
      {filteredServices.slice(0, amount).map(service => (
        <ServicePreviewCard key={service.id} service={service} />
      ))}
    </Container>
  );
}

export default ServicesTicker;
