import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import useFilterLocale from "hooks/useFilterLocale";
import React, { useMemo } from "react";
import styled from "styled-components";

const getInfo = graphql`
  {
    allContentfulFooter {
      edges {
        node {
          node_locale
          logo {
            fluid(maxHeight: 50, maxWidth: 357) {
              ...GatsbyContentfulFluid
            }
          }
          paragraph
        }
      }
    }
  }
`;

const Image = styled(Img)`
  width: 314px;
  height: 38px;
`;

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 20
  },
  paragraph: {
    color: "#fff",
    marginTop: 15,
    fontWeight: 400,
    fontSize: "16px",
    width: "314px"
  }
}));

function LogoAndParagraph() {
  const classes = useStyles();
  const data = useStaticQuery(getInfo);
  const infoData = useMemo(
    () => data.allContentfulFooter.edges.map(edge => edge.node),
    [data.allContentfulFooter.edges]
  );
  const filteredInfoData = useFilterLocale(infoData);

  return (
    <Box className={classes.container}>
      <Image fluid={filteredInfoData[0].logo.fluid} />

      <p className={classes.paragraph} style={{ fontSize: "16px" }}>
        {filteredInfoData[0].paragraph}
      </p>
    </Box>
  );
}

export default LogoAndParagraph;
