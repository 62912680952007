import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
// import Button from '../components/Button'

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 70%;
  flex-wrap: wrap;
  align-content: center;
  margin: 0 auto;
`

const HeadingContainer = styled.div`
  width: 100%;
  justify-content: space-around;
  align-items: center;
  h1 {
    font-family: "Baskerville";
    font-size: 50px;
    font-weight: bold;
    text-align: center;
  }
`
const DarkContainer = styled.div`
  width: 350px;
  height: 500px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.6;
  z-index: 1000;
  
  }
`

const OfferContainer = styled.div`
  width: 350px;
  height: 500px;
  position: relative;

  overflow: hidden;
  margin: 15px;

  &:hover {
    .darkcontainer {
      opacity: 0.1;
    }
    .heading, .paragraph {
      text-shadow: 3px 3px 6px #666;
    }
  }
 
  .content {
    position: absolute;
    color: #fff;
    z-index: 1200;
    top: 0;
    padding: 20px;
    margin: 0 auto;
    pointer-events: none;
  }
  .heading {
    font-weight: bold;
    letter-spacing: 2px;
    margin: 10px 0;
  }

  .paragraph {
    text-align: left;
    font-size: 21px;
    font-weight: 400;

  }
`

const Image = styled(Img)`
  width: 350px;
  height: 500px;
  position: absolute;
  z-index: 150;
`

function OfferPreviewCard (props) {
  const { offer } = props
  return (
    <div>
      <Link to={offer.to}>
        <OfferContainer>
          <Image fluid={offer.coverPhoto.fluid} />
          <DarkContainer className="darkcontainer" />
          <div className="content">
            <h2 className="heading">{offer.title}</h2>
            <p className="paragraph">{offer.shortDescription}</p>
          </div>
        </OfferContainer>
      </Link>
    </div>
  )
}

export default OfferPreviewCard
