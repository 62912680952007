import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import useFilterLocale from "hooks/useFilterLocale";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import BodyRenderer from "../BodyRenderer";

const StyledForm = styled.form`
  margin: 0 auto;
`

const ErrorMessage = styled.p`
  font-size: 18px;
  color: red;
  ${({ error }) => (error ? "" : "display: none;")}
`;

const SuccessMessage = styled.p`
  font-size: 18px;
  color: #71cc98;
  ${({ open }) => (open ? "" : "display: none;")}
`;
const getFormData = graphql`
  {
    allContentfulContactForm {
      edges {
        node {
          formHeading
          successMessage
          nameTextFieldPlaceholder
          emailTextFieldPlaceholder
          telefonTextFieldPlaceholder
          subjectTextFieldPlaceholder
          messageTextFieldPlaceholder
          buttonLabel
          id
          node_locale
          checkBoxText {
            json
          }
          checkboxErrorMessage
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  container: {},
  textField: {
    borderRadius: 0,
    width: "100%"
  },
  formContainer: {
    margin: "0 auto"
  },
  button: {
    backgroundColor: "black",
    color: "white",
    borderRadius: 0,
    boxShadow: "none",
    marginTop: 10,
    "&:hover": {
      outline: "none",
      backgroundColor: "black"
    }
  },
  buttonContainer: {
    position: "relative"
  },
  inquiry: {},
  successMessage: {
    fontSize: "18px",
    color: theme.palette.primary.main
  },
  checkBoxContainer: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none"
    },
    "& div": {
      fontSize: "13px",
      lineHeight: "15px"
    }
  }
}));

function createHandleChange(setState) {
  return event => setState(event.target.value);
}

function ContactForm(props) {
  const classes = useStyles();
  const data = useStaticQuery(getFormData);

  const formData = useMemo(
    () => data.allContentfulContactForm.edges.map(edge => edge.node),
    [data.allContentfulContactForm.edges]
  );
  const filteredFormData = useFilterLocale(formData);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telefon, setTelefon] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();

    if (isChecked) {
      fetch(
        "https://europe-west1-sb-mailer.cloudfunctions.net/submitForm/naItfq3roMcaRYWs504b",
        {
          method: "POST",

          headers: {
            "Content-Type": "application/json"
          },
          referrer: "no-referrer",

          body: JSON.stringify({
            name,
            email,
            telefon,
            subject,
            message
          })
        }
      );

      setName("");
      setEmail("");
      setTelefon("");
      setSubject("");
      setMessage("");
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  return (
    <Grid container style={{ justifyContent: 'center' }}>
      <Grid container>
        <Grid item sm={12}>
          <Typography className={classes.inquiry} variant="h3" style={{ textAlign: 'center' }}>
            {filteredFormData[0].formHeading}
          </Typography>
          <SuccessMessage open={open}>
            {filteredFormData[0].successMessage}
          </SuccessMessage>
        </Grid>
      </Grid>

      <Grid container style={{ maxWidth: 600 }}>
        <Grid item sm={12}>
          <StyledForm onSubmit={handleSubmit}>
            <Grid>
              <Grid container spacing={2}>
                <Grid item className={classes.formContainer} md={6} xs={12}>
                  <TextField
                    required
                    className={clsx(classes.textField, classes.dense)}
                    label={filteredFormData[0].nameTextFieldPlaceholder}
                    margin="dense"
                    value={name}
                    variant="filled"
                    onChange={createHandleChange(setName)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    className={clsx(classes.textField, classes.dense)}
                    label={filteredFormData[0].emailTextFieldPlaceholder}
                    margin="dense"
                    type="email"
                    value={email}
                    variant="filled"
                    onChange={createHandleChange(setEmail)}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    className={clsx(classes.textField, classes.dense)}
                    label={filteredFormData[0].telefonTextFieldPlaceholder}
                    margin="dense"
                    value={telefon}
                    variant="filled"
                    onChange={createHandleChange(setTelefon)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    className={clsx(classes.textField, classes.dense)}
                    label={filteredFormData[0].subjectTextFieldPlaceholder}
                    margin="dense"
                    value={subject}
                    variant="filled"
                    onChange={createHandleChange(setSubject)}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    multiline
                    className={classes.textField}
                    label={filteredFormData[0].messageTextFieldPlaceholder}
                    rows="4"
                    value={message}
                    variant="filled"
                    onChange={createHandleChange(setMessage)}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Box className={classes.checkBoxContainer}>
                  <ErrorMessage error={error}>
                    {filteredFormData[0].checkboxErrorMessage}
                  </ErrorMessage>

                  <div>
                    <input
                      type="checkbox"
                      onChange={() => {
                        setIsChecked("true");
                        setError(false);
                      }}
                    />
                    <p style={{ marginLeft: "22px", marginTop: "-18px" }}>
                      <BodyRenderer
                        json={filteredFormData[0].checkBoxText.json}
                      />
                    </p>
                  </div>
                </Box>

                <Grid item className={classes.buttonContainer} xs={12}>
                  <Button
                    className={classes.button}
                    type="submit"
                    variant="contained"
                    size="large"
                  >
                    {filteredFormData[0].buttonLabel}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </StyledForm>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ContactForm;
