import { makeStyles } from "@material-ui/core/styles";
import { graphql, useStaticQuery } from "gatsby";
import useFilterLocale from "hooks/useFilterLocale";
import React, { useMemo } from "react";
import styled from "styled-components";

const getContactInfo = graphql`
  {
    allContentfulFooter {
      edges {
        node {
          node_locale
          contactInfoHeading
          contactInfoLine1
          contactInfoLine2
          contactInfoLine3
          contactInfoLine4
        }
      }
    }
  }
`;
const List = styled.ul`
  list-style-type: none;
  padding: 0px;
  margin: 0;
`;

const ListItem = styled.li`
  color: white;
  font-size: 16px;
  flex-wrap: nowrap;
  margin-bottom: 10px;
  font-weight: 400;
  &:before {
    content: ">";
    color: #71cc98;
    margin-right: 10px;
  }
`;

const useStyles = makeStyles({
  heading: {
    color: "#fff",
    textTransform: "uppercase",
    fontSize: 16,
    margin: 0,
    letterSpacing: "2px",
    fontWeight: "bold"
  }
});

function ContactInfo() {
  const classes = useStyles();
  const data = useStaticQuery(getContactInfo);

  const contactInfo = useMemo(
    () => data.allContentfulFooter.edges.map(edge => edge.node),
    [data.allContentfulFooter.edges]
  );
  const filteredContactInfo = useFilterLocale(contactInfo);

  return (
    <div>
      <p className={classes.heading}>
        {filteredContactInfo[0].contactInfoHeading}
      </p>
      <List>
        <ListItem>{filteredContactInfo[0].contactInfoLine1}</ListItem>
        <ListItem>{filteredContactInfo[0].contactInfoLine2}</ListItem>
        <ListItem>{filteredContactInfo[0].contactInfoLine3}</ListItem>
        <ListItem>{filteredContactInfo[0].contactInfoLine4}</ListItem>
      </List>
    </div>
  );
}

export default ContactInfo;
