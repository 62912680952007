import {
  borders,
  compose,
  display,
  flexbox,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
  css
} from '@material-ui/system'
import { styled } from '@material-ui/styles'

export const innerStyleFunction = css(
  compose(
    borders,
    display,
    flexbox,
    positions,
    palette,
    shadows,
    sizing,
    spacing,
    typography
  )
)

const styleFunction = props => ({
  '&&': innerStyleFunction(props)
})

/**
 * @ignore - do not document.
 */
const Box = styled('div')(styleFunction, { name: 'MuiBox' })

export default Box
