import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import ReactMapGL, { Marker, NavigationControl } from "react-map-gl";
import styled from "styled-components";

import PinIcon from "../../images/map-marker.png";

const MapContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px, 20px;
  width: 100%;
  position: relative;
`;
const NavControl = styled.div`
  top: 10px;
  left: 10px;
  position: absolute;
`;

const Icon = styled.img`
  height: 40px;
  width: 40px;
`;

const Container = styled.div`
  width: 100%;
`;

const initial_viewport = {
  latitude: 48.330582,
  longitude: 11.740025,
  zoom: 11
};

const Map = ({ props }) => {
  // const { latitude, longitude } = props;
  // console.log(props)
  const [viewport, setViewport] = useState(initial_viewport);
  return (
    <Container>
      <MapContainer>
        <ReactMapGL
          width="100%"
          height="500px"
          mapboxApiAccessToken="pk.eyJ1Ijoibmlrb2xvdmFyYWRtaWxhIiwiYSI6ImNqeHRhbW4xeDBtam8zZHFvbmVuN3o5M2kifQ.gdDjSh_T6CYCAh_JfhSEGQ"
          mapStyle="mapbox://styles/mapbox/streets-v9"
          onViewportChange={newViewport => setViewport(newViewport)}
          {...viewport}
        >
          <NavControl>
            <NavigationControl
              onViewportChange={newViewport => setViewport(newViewport)}
            />
          </NavControl>
          <Marker latitude={48.330582} longitude={11.740025}>
            <Icon src={PinIcon} />
          </Marker>
        </ReactMapGL>
      </MapContainer>
    </Container>
  );
};

export default Map;
