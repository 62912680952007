import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useMemo } from "react";
import styled from "styled-components";

const Image = styled.img`
  width: 30px;
  height: 20px;
  margin: 0px 5px;
  margin-bottom: 0px;
  margin-top: 10px;
`;

const getIcons = graphql`
  {
    contentfulLayout {
      languageSwitcherDe {
        file {
          url
        }
      }
      languageSwitcherEn {
        file {
          url
        }
      }
    }
  }
`;

function LanguageSwitcher() {
  const data = useStaticQuery(getIcons);

  return (
    <div>
      <Link to="/de">
        <Image src={data.contentfulLayout.languageSwitcherDe.file.url} />
      </Link>

      <Link to="/en">
        <Image src={data.contentfulLayout.languageSwitcherEn.file.url} />
      </Link>
    </div>
  );
}

export default LanguageSwitcher;
