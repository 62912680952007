import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { Box } from 'components'
import { graphql, useStaticQuery } from 'gatsby'
import useFilterLocale from 'hooks/useFilterLocale'
import React, { useMemo } from 'react'

import Slider, { useSliderIndex } from './Slider'
import VehicleDetails from './VehicleDetails'

const useStyles = makeStyles(theme => ({
  arrowContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    fontSize: 70,
    marginTop: '-20px',
    marginBottom: '30px',
    cursor: 'pointer',
    backgroundColor: '#71cc98',
    '&:hover': {
      color: '#fff'
    }
  }
}))

function FleetSlider () {
  const classes = useStyles()
  const data = useStaticQuery(query) // eslint-disable-line no-use-before-define
  const slides = useMemo(() => data.vehicles.edges.map(edge => edge.node), [
    data.vehicles.edges
  ])
  const filteredSlides = useFilterLocale(slides)
  const sliderIndex = useSliderIndex(filteredSlides.length)

  return (
    <Container maxWidth="xl">
      <Box>
        <Slider slides={filteredSlides} {...sliderIndex.props} />
        <br />
        <Box className={classes.arrowContainer}>
          <KeyboardArrowLeft
            className={classes.icon}
            onClick={sliderIndex.prev}
          />
          <KeyboardArrowRight
            className={classes.icon}
            onClick={sliderIndex.next}
          />
          {/* <img src={LeftArrow} onClick={sliderIndex.prev} />
        <img src={RightArrow} onClick={sliderIndex.next} /> */}
        </Box>
        {/* <Button onClick={sliderIndex.prev}>Prev</Button>
      <Button onClick={sliderIndex.next}>Next</Button> */}
        <VehicleDetails vehicle={filteredSlides[sliderIndex.activeIndex]} />
      </Box>
    </Container>
  )
}

const query = graphql`
  {
    vehicles: allContentfulFleetType {
      edges {
        node {
          vehicleTitle
          vehiclePhoto {
            fluid(maxWidth: 1600, maxHeight: 900, quality: 90) {
              ...GatsbyContentfulFluid
            }
          }

          secondVehiclePhoto {
            fluid(maxWidth: 1600, maxHeight: 900, quality: 90) {
              ...GatsbyContentfulFluid
            }
          }
          slug
          shortVehicleDescription
          price
          numberOfPassengers
          numberOfLuggagePieces
          numberOfHandcarryPieces
          node_locale
          id
          buttonLabel
          mainDescription {
            json
          }
        }
      }
    }
  }
`

export default FleetSlider
