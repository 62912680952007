import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { LocationSliderCard } from 'components/LocationPreviewCard'
import { graphql, StaticQuery, useStaticQuery } from 'gatsby'
import useFilterLocale from 'hooks/useFilterLocale'
import React, { useCallback, useMemo, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { mod } from 'react-swipeable-views-core'
import { bindKeyboard, virtualize } from 'react-swipeable-views-utils'

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews))

const getLocations = graphql`
  {
    allContentfulLocation {
      edges {
        node {
          locationName
          id
          node_locale
          slug
          photo {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyContentfulFluid
            }
            title
          }
        }
      }
    }
  }
`

const styles = {
  root: {
    paddingRight: 'calc(100% - 350px)',
    marginTop: '30px'
  },
  slideContainer: {},
  slide: {
    minHeight: 100
  }
}

const useStyles = makeStyles(theme => ({
  arrowsContainer: {
    width: 200
  },
  button: {
    zIndex: 2000,
    color: 'white'
  },
  arrowContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    fontSize: 70,
    cursor: 'pointer',
    backgroundColor: '#71cc98',
    '&:hover': {
      color: '#fff'
    }
  }
}))

function makeSlideRenderer (locations, activeIndex) {
  return function slideRenderer (params) {
    const { index, key } = params
    const idx = mod(index, locations.length)

    return (
      <Box key={key}>
        <LocationSliderCard
          index={idx + 1}
          location={locations[idx]}
          noOverlay={activeIndex === index}
        />
      </Box>
    )
  }
}

function DemoVirtualize () {
  const data = useStaticQuery(getLocations)
  const classes = useStyles()

  const [index, setIndex] = useState(0)
  const handleNext = useCallback(() => setIndex(idx => idx + 1), [])
  const handlePrev = useCallback(() => setIndex(idx => idx - 1), [])
  const handleChangeIndex = useCallback(idx => setIndex(idx), [])

  const slides = useMemo(
    () => data.allContentfulLocation.edges.map(edge => edge.node),
    [data.allContentfulLocation.edges]
  )
  const filteredSlides = useFilterLocale(slides)
  const slideRenderer = useMemo(
    () => makeSlideRenderer(filteredSlides, index),
    [filteredSlides, index]
  )

  return (
    <Container maxWidth="xl">
      <Box className={classes.sliderContainer}>
        <VirtualizeSwipeableViews
          enableMouseEvents
          index={index}
          overscanSlideAfter={5}
          overscanSlideBefore={2}
          slideRenderer={slideRenderer}
          slideStyle={styles.slideContainer}
          style={styles.root}
          onChangeIndex={handleChangeIndex}
        />

        <Box className={classes.arrowContainer}>
          <KeyboardArrowLeft className={classes.icon} onClick={handlePrev} />
          <KeyboardArrowRight className={classes.icon} onClick={handleNext} />
        </Box>
      </Box>
    </Container>
  )
}

export default DemoVirtualize
