import Container from '@material-ui/core/Container'
import { Box } from 'components'
import React from 'react'

function Asset (props) {
  const {
    inline, title, description, file
  } = props
  const aspectRatio = file.details.image.height / file.details.image.width
  return (
  // pb = {`${aspectRatio * 100}%`}
  // without creating a new component this is the easiest way to fix about us
    <Box class={title === 'Group' ? 'MuiContainer-root MuiContainer-maxWidthLg' : ''} position="relative" spacing={4}>
      <Box
        alt={description}
        component="img"
        left={0}
        position="relative"
        src={file.url}
        title={title}
        top={0}
        width="100%"
      />
    </Box>
  )
}

export default Asset
