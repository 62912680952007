import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import useNavigationLinks from 'hooks/useNavigationLinks'
import React from 'react'
import styled from 'styled-components'

const LogoContainer = styled.div`
  flex: 1;
  margin: 20px 0;
`

const Image = styled(Img)`
  width: 356px;
  height: 44px;
  @media (max-width: 600px) {
    width: 250px;
    height: 30px;
  }
`

const getLogo = graphql`
  {
    contentfulLayout {
      logo {
        fluid(maxHeight: 50, maxWidth: 357) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

function Logo () {
  const data = useStaticQuery(getLogo)
  const homeLink = useNavigationLinks().filter(
    link => link.title === 'Home'
  )[0]

  return (
    <Link to={homeLink.to}>
      <LogoContainer>
        <Image fluid={data.contentfulLayout.logo.fluid} />
      </LogoContainer>
    </Link>
  )
}

export default Logo
