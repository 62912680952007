import Img from "gatsby-image";
import React from "react";
import styled from "styled-components";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const Image = styled(Img)`
  width: 100%;
`;

const ContactCard = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  width: 350px;
  border-right: 12px solid black;
  justify-content: center;
  padding: 0px 20px;
  @media (max-width: 960px) {
    border-right: none;
    border-left: 12px solid black;
  }
  h2 {
    margin: 20px;
    text-align: left;
    align-self: flex-start;
    margin-left: 0;
    font-size: 30px;
    font-weight: 700;
    line-height: 29.98px;
  }
  div {
    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
      margin: 5px;
    }
    .name {
      font-weight: bold;
    }
  }
`;

const useStyles = makeStyles(theme => ({
  card: {
    width: 350
  },
  media: {
    height: 300
  }
}));

function ContactPersonCard(props) {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h4">{props.contactPersonHeadline}</Typography>
      <Card className={classes.card}>
        <CardMedia image={props.photo.file.url} className={classes.media} />
        <CardContent>
          <div>
            <Typography variant="h3" align="center" textDecoration="underline">
              {props.name}
            </Typography>
            <Typography variant="h5" align="center">
              Telefon: {props.telephone}
            </Typography>
            <Typography variant="h5" align="center">
              Email: {props.email}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </>
  );
}

export default ContactPersonCard;
