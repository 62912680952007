import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { CallToActionButton, Footer, Header } from 'components'
import React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  }
}))

function Layout ({ children }) {
  const classes = useStyles()
  return (
    <>
      <Header />
      {children}
      {/*  <CallToActionButton />   */}
      <Footer />
    </>
  )
}

export default Layout
