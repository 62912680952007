import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import useFilterLocale from 'hooks/useFilterLocale';
import React, { useMemo } from 'react';
import Truncate from 'react-truncate';
import styled from 'styled-components';

const Image = styled(Img)`
  width: 90%;
  height: 100%;
  margin-right: 0px;
`

const getLatestNews = graphql`
  {
    allContentfulNews(sort: { fields: date, order: DESC }) {
      edges {
        node {
          node_locale
          slug
          author
          date(formatString: "MMMM DD, YYYY")
          newsContent {
            json
          }

          title
          coverPhoto {
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f2f2f2',
    height: 'auto',
    marginBottom: 50
  },
  heading: {
    fontFamily: 'Libre Baskerville',
    fontWeight: 700,
    textAlign: 'right',
    margin: 30,
    fontSize: 70,
    lineHeight: '90px',
    [theme.breakpoints.down('md')]: {
      fontSize: 40
    }
  },
  textContainer: {
    fontSize: '20px',
    width: '90%'
  },
  boxcontainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-around'
  },
  info: {
    padding: 20,
    '& p': {
      margin: 0
    }
  },
  author: {
    textTransform: 'uppercase',
    fontSize: '23px',
    color: '#2a2928'
  },
  date: {
    fontFamily: 'Libre Baskerville',
    fontSize: '18px',
    fontWeight: 400,
    color: '#918e8b'
  },
  link: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: '#71cc98'
  },
  image: {}
}))

function LatestNews (props) {
  const classes = useStyles()
  const data = useStaticQuery(getLatestNews)
  const { amount } = props

  const articles = useMemo(
    () => data.allContentfulNews.edges.map(edge => edge.node),
    [data.allContentfulNews.edges]
  )

  const filteredArticles = useFilterLocale(articles)

  return (
    <Grid container className={classes.root}>
      {filteredArticles.slice(0, amount).map(news => (
        <Grid container justify="flex-end">
          <Grid item className={classes.boxcontainer} md={6} sm={12} xl={4}>
            <Box className={classes.textContainer}>
              <Typography className={classes.heading} variant="h1">
                {news.title}
              </Typography>

              <Truncate
                ellipsis={(
<>
                    <span>...</span>
                    <br />
                    <Link className={classes.link} to={news.to}>
                      Read more&nbsp;&#8594;
                    </Link>
                  </>
)}
                lines={12}
              >
                {documentToReactComponents(news.newsContent.json)}
              </Truncate>
            </Box>
          </Grid>

          <Grid item direction="column" justify="flex-end" md={6} sm={12}>
            <Image className={classes.image} fluid={news.coverPhoto.fluid} />
          </Grid>
          <Grid container justify="space-between" sm={12}>
            <Grid item sm={6} />
            <Grid item sm={6}>
              <div className={classes.info}>
                <p className={classes.author}>Written by: {news.author}</p>
                <p className={classes.date}> {news.date}</p>
              </div>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default LatestNews
