import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants'

import BarlowRegular from './fonts/barlow-regular.woff2'

const Barlow = {
  fontFamily: 'Barlow',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Barlow'),
    local('Barlow-regular'),
    url(${BarlowRegular}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
}

// A custom theme for this app
const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {}
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [Barlow, BarlowRegular],
        body: {
          backgroundColor: '#ffffff'
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#71cc98'
    },
    secondary: {
      main: '#ebebeb'
    },
    error: {
      main: red.A400
    },
    background: {
      default: '#fff'
    }
  },
  typography: {
    h1: {
      fontSize: 35,
      fontFamily: 'Libre Baskerville',
      margin: '15px 0px',
      lineHeight: '45px',
      textAlign: 'left'
    },
    h2: {
      fontSize: 27,
      fontFamily: 'Libre Baskerville',
      fontWeight: 600,
      lineHeight: '40px',
      margin: '15px 0px',
      textAlign: 'left'
    },
    h3: {
      fontSize: 22,
      fontFamily: 'Libre Baskerville',

      lineHeight: '30px',
      margin: '20px 0px',
      textAlign: 'left'
    },
    h4: {
      fontSize: 20,
      fontFamily: 'Libre Baskerville',
      fontWeight: 'bold',
      lineHeight: '40px',
      margin: '20px 0px',
      textAlign: 'left'
    },
    h5: {
      fontFamily: 'Libre Baskerville',
      fontSize: 17,
      margin: '10px 0px',
      fontWeight: 'bold'
    },

    h6: {
      marginTop: 20,
      marginBottom: 10
    },

    body2: {
      fontSize: '18px',
      fontFamily: 'Barlow, sans-serif',
      fontWeight: '200',
      lineHeight: '30px',
      marginBottom: '50px'
      /*  paddingTop: 5, */
    },

    button: {}
  }
})

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  window.theme = theme
}

export default theme
