import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { Typography } from '@material-ui/core'
import {
  Asset,
  Columns,
  ContactForm,
  ContactInfo,
  ContactPerson,
  HeroComponent,
  Map,
  NewsCard,
  Offer,
  OneColumnText,
  RequestIframe,
  TestimonialsSlider,
  Ticker
  // TestimonialsSlider,
} from 'components'
import { usePageContext } from 'components/PageContext'
import { Link } from 'gatsby'
import React, { useMemo } from 'react'

const DEFAULT_LOCALE = 'en'
function useLocale() {
  // This is a stub, get the locale from somewhere real!
  const pageContext = usePageContext()
  return pageContext.locale
}

const embeds = {
  columns: ({ fields }) => (
    <Columns left={fields.contentLeft} right={fields.contentRight} />
  ),
  oneColumnCenteredText: ({ fields }) => (
    <OneColumnText centered={fields.centered} content={fields.content} />
  ),
  ticker: ({ fields }) => (
    <Ticker
      amount={fields.amount}
      contentType={fields.contentType}
      variant={fields.variant}
    />
  ),
  iframe: ({ fields }) => (
    <RequestIframe title={fields.title} url={fields.url} />
  ),
  // textAndImageComponent: ({ fields }) => (
  //   <TextAndImageComponent
  //     content={fields.content}
  //     heading={fields.heading}
  //     image={fields.image}
  //     variant={fields.variant}
  //   />
  // ),
  // teamMember: ({ fields }) => (
  //   <TeamMember name={fields.name} photo={fields.photo} role={fields.role} />
  // ),
  news: ({ fields }) => (
    <NewsCard
      description={fields.newsContent}
      heading={fields.mainHeading}
      image={fields.coverPhoto}
      title={fields.title}
    />
  ),
  contactInfo: ({ fields }) => (
    <ContactInfo
      contactInfoLine={fields.contactInfoLine}
      heading={fields.heading}
    />
  ),
  contactForm: ({ fields }) => <ContactForm formHeading={fields.formHeading} />,
  map: ({ fields }) => (
    <Map latitude={fields.latitude} longitude={fields.longitude} />
  ),
  offer: ({ fields }) => (
    <Offer
      buttonLabel={fields.buttonLabel}
      capacity={fields.capacity}
      heading={fields.mainHeading}
      image={fields.coverPhoto}
      note={fields.note}
      price={fields.tourPrice}
      shortDescription={fields.shortDescription}
      subline={fields.subline}
      title={fields.title}
    />
  ),

  testimonialSliderTicker: ({ fields }) => (
    <TestimonialsSlider amount={fields.amount} />
  ),

  heroComponent: ({ fields }) => (
    <HeroComponent
      heading={fields.heading}
      mainImage={fields.mainImage}
      secondaryImage={fields.secondaryImage}
      subheading={fields.subheading}
    />
  ),
  contactPerson: ({ fields }) => (
    <ContactPerson
      contactPersonHeadline={fields.contactPersonHeadline}
      email={fields.email}
      name={fields.name}
      photo={fields.photo}
      telephone={fields.telephone}
    />
  )
}

// button: ({ fields }) => <Button>{fields.title}</Button>,

function getNodeFields(fields, locale) {
  return fields && Object.entries(fields).reduce((acc, [field, values]) => {
    let value = values[locale] || values[DEFAULT_LOCALE] || values

    if (!value) {
      throw new Error(
        `getNodeFields: Couldnt choose value for field '${field}'`
      )
    }
    if (value && value.sys && value.fields) {
      value = getNodeFields(value.fields, locale)
    }
    return { ...acc, [field]: value }
  }, {})
}

function EmbeddedEntry(props) {
  const { inline, node } = props
  const locale = useLocale()
  const pageContext = usePageContext()
  const { target } = node.data
  const fields = useMemo(() => getNodeFields(target.fields || {}, locale), [
    locale,
    target.fields
  ])
  if (!target.sys.contentType) {
    console.warn(
      `Found a ${target.sys.linkType} without content attached, this usually means the linked Entry was deleted. Check the page: '${pageContext.locale}/${pageContext.slug}'`
    )
    return null
  }
  const componentName = target.sys.contentType.sys.id
  const Component = embeds[componentName]

  if (!Component) return null
  return <Component fields={fields} inline={inline} name={componentName} {...props} />
}

function EmbeddedAsset(props) {
  const { inline, node } = props
  const locale = useLocale()
  const fields = useMemo(() => getNodeFields(node.data.target.fields, locale), [
    locale,
    node.data.target.fields
  ])

  return (
    <Asset
      description={fields.description}
      file={fields.file}
      inline={inline}
      title={fields.title}
    />
  )
}

function EmbeddedAssetLink(props) {
  const { inline, node } = props
  const locale = useLocale()
  const fields = useMemo(() => getNodeFields(node.data.target.fields, locale), [
    locale,
    node.data.target.fields
  ])
  return (
    <a
      href={`https:${fields.file.url}`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <BodyRenderer json={node} />
    </a>
  )
}

function EmbeddedLink(props) {
  const { inline, node } = props
  const locale = useLocale()
  const fields = useMemo(() => getNodeFields(node.data.target.fields, locale), [
    locale,
    node.data.target.fields
  ])
  if (!fields) return <BodyRenderer json={node} />
  return (
    <Link
      style={{ color: '#71cc98', textDecoration: 'none' }}
      to={`/${locale}/${fields.slug === 'home' ? '' : fields.slug}`}
    >
      <BodyRenderer json={node} />
    </Link>
  )
}

// Fix for Contenful <li> wrapping children in <p> which affects global styles
const NaturalListItem = ({ node }) => (
  <li>
    {documentToReactComponents(node, {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (n, ch) => ch,
        [BLOCKS.LIST_ITEM]: (n, ch) => ch
      }
    })}
  </li>
)

const options = {
  renderMark: {
    // [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <Typography variant="h1">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Typography variant="h2">{children}</Typography>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Typography variant="h3">{children}</Typography>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Typography variant="h4">{children}</Typography>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Typography variant="h5">{children}</Typography>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Typography variant="h6">{children}</Typography>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <NaturalListItem node={node}>{children}</NaturalListItem>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography component="div" variant="body2">
        {children}
      </Typography>
    ),
    [INLINES.EMBEDDED_ENTRY]: node => <EmbeddedEntry inline node={node} />,
    [BLOCKS.EMBEDDED_ENTRY]: node => (
      <EmbeddedEntry inline={false} node={node} />
    ),
    [BLOCKS.EMBEDDED_ASSET]: node => (
      <EmbeddedAsset inline={false} node={node} />
    ),
    [INLINES.EMBEDDED_ASSET]: node => <EmbeddedAsset inline node={node} />,
    [INLINES.ENTRY_HYPERLINK]: node => <EmbeddedLink inline node={node} />,
    [INLINES.ASSET_HYPERLINK]: node => <EmbeddedAssetLink inline node={node} />
  }
}


export default function BodyRenderer(props) {
  const { json } = props
  const rendered = documentToReactComponents(json, options)
  return rendered
}
