import { BodyRenderer, Box } from 'components'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

const Image = styled(Img)`
  height: 500px;
`

const Content = styled.div`
  div {
    line-height: 30px;
    margin: 10px 0px;
    h2 {
      margin: 0;
      font-size: 30px;
      text-transform: uppercase;
    }
    h3 {
      font-weight: bold;
      font-family: Barlow, sans-serif;
    }
    hr {
      border: 2px solid black;
    }
  }
`

const VehicleDetails = React.memo((props) => {
  const { vehicle } = props

  return (
    <Box>
      <Box>
        <Image fluid={vehicle.secondVehiclePhoto.fluid} />

        <Content>
          <BodyRenderer json={vehicle.mainDescription.json} />
        </Content>
      </Box>
    </Box>
  )
})

export default VehicleDetails
