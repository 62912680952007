import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

const Image = styled(Img)`
  height: 100%;
  width: 100%;
`

const Slide = styled.div`
  position: relative;
  height: 450px;
  width: 350px;
  margin: 0;

  background-color: #000000;
  top: 0;
  left: 0;
  opacity: 1;
  overflow: hidden;

  h2 {
    position: absolute;

    top: 30px;
    left: 25px;
    color: white;
    font-family: Libre Baskerville;
    text-shadow: 3px 3px 6px #666;
    font-size: 31px;
    font-weight: bold;
    text-align: left;
    line-height: 35px;
  }
  .index {
    position: absolute;
    text-shadow: 3px 3px 6px #666;
    top: 10px;
    left: 25px;
    color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 45px;
    margin: 0;
    font-family: Barlow;
  }
`

const SlideCover = styled.div`
  height: 450px;
  width: 350px;
  position: absolute;
  background-color: #000000;
  top: 0;
  left: 0;
  opacity: 0.4;
  overflow: hidden;
  opacity: ${props => (props.noOverlay ? 0 : 0.4)};
`

const LocationContainer = styled.div`
  position: relative;
  height: 350px;
  width: 350px;
  margin: ${({ noMargin }) => (noMargin ? 0 : 5)}px;
  position: absolute;
  background-color: #000000;
  top: 0;
  left: 0;
  opacity: 0.4;
  overflow: hidden;

  h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    text-transform: uppercase;
    color: white;
    z-index: 1500;
  }
`
const Cover = styled.div`
  height: 350px;
  width: 350px;
  position: absolute;
  background-color: #000000;
  top: 0;
  left: 0;
  opacity: 0.6;
  overflow: hidden;
  z-index: 2500;
  &:hover {
    opacity: 0.1;
  }
`

export const LocationPreviewCard = (props) => {
  const { location, noMargin, noOverlay } = props
  return (
    <Link to={location.to}>
      <LocationContainer noMargin={noMargin}>
        <Image alt={location.photo.title} fluid={location.photo.fluid} />
        {/* <Image fluid={props.image.fluid} /> */}
        <h2>{location.locationName}</h2>
        <Cover />
      </LocationContainer>
    </Link>
  )
}

export const LocationSliderCard = (props) => {
  const {
    index, location, noMargin, noOverlay
  } = props
  return (
    <Link to={location.to}>
      <Slide noMargin={noMargin}>
        <Image alt={location.photo.title} fluid={location.photo.fluid} />
        {/* <Image fluid={props.image.fluid} /> */}

        <p className="index">{`0${index}`}</p>
        <h2>{location.locationName}</h2>
        <SlideCover noOverlay={noOverlay} />
      </Slide>
    </Link>
  )
}
