import { Link } from "gatsby";
import useSiteMapLinks from "hooks/useSiteMapLinks";
import React from "react";
import styled from "styled-components";

const ListItem = styled.li`
  color: white;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;

  &:before {
    content: ">";
    color: #71cc98;
    margin-right: 10px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;
const List = styled.ul`
  list-style-type: none;
  padding: 0%;
  column-count: 2;
  column-fill: balance;
  column-gap: 40px;
  width: 340px;
  margin: 0;
`;

function SiteMap() {
  const links = useSiteMapLinks();

  return (
    <List>
      {links.map(link => (
        <StyledLink key={link.id} to={link.to}>
          <ListItem> {link.title}</ListItem>
        </StyledLink>
      ))}
    </List>
  );
}

export default SiteMap;
