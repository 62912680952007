import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, useStaticQuery } from 'gatsby';
import useFilterLocale from 'hooks/useFilterLocale';
import React, { useMemo } from 'react';

import TeamMember from '../TeamMember';

const getTeamMembers = graphql`
  {
    allContentfulTeamMember {
      edges {
        node {
          node_locale
          name
          role
          photo {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          team
        }
      }
    }
  }
`

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '30 auto',
    marginTop: '50px'
  }
})

function TeamMembersTicker(props) {
  const data = useStaticQuery(getTeamMembers)
  const classes = useStyles()
  const { amount } = props

  const members = useMemo(
    () => data.allContentfulTeamMember.edges.map(edge => edge.node),
    [data.allContentfulTeamMember.edges]
  )

  const filteredMembers = useFilterLocale(members)

  return (
    <>
      <Container className={classes.container} maxWidth="lg">
        {filteredMembers.slice(0, amount).map(member => (
          <TeamMember key={member.id} member={member} />
        ))}
      </Container>
    </>
  )
}

export default TeamMembersTicker
