import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { graphql, useStaticQuery } from "gatsby";
import useFilterLocale from "hooks/useFilterLocale";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import BodyRenderer from "../BodyRenderer";

const getNewsletterInfo = graphql`
  {
    allContentfulFooter {
      edges {
        node {
          node_locale
          newsletterHeading
          newsletterInfoLine
          newsletterTextFieldPlaceholder
          newsletterButtonLabel
          newsletterCheckBoxText {
            json
          }
          newsletterSuccessMessage
          newsletterErrorMessage
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: "350px"
  },
  heading: {
    color: "#fff",
    textTransform: "uppercase",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "2px",
    marginTop: 0
  },

  button: {
    backgroundColor: "#252525",
    opacity: 0.67,
    color: "white",
    width: "121px",
    borderRadius: 0,
    marginLeft: 5,

    "&:hover": {
      backgroundColor: "#252525"
    }
  },
  input: {
    color: "red",
    width: "203px",
    height: "40px",
    border: "1px solid #898989",
    backgroundColor: "#252525",
    opacity: "0.67",
    outline: "none",
    "&::placeholder": {
      color: "#afadad",
      fontSize: "14px",
      paddingLeft: "20px",
      fontStyle: "italic"
    }
  },
  infoLine: {
    fontSize: 16,
    fontWeight: 400
  },
  inputContainer: {
    display: "flex",
    margin: "10px 0px",
    "& div": {
      lineHeight: "25px"
    }
  }
}));

const ErrorMessage = styled.p`
  font-size: 28px;
  color: red;
  ${({ error }) => (error ? "" : "display: none;")}
`;

const SuccessMessage = styled.p`
  font-size: 18px;
  color: #71cc98;
  ${({ open }) => (open ? "" : "display: none;")}
`;

function Newsletter() {
  const classes = useStyles();
  const data = useStaticQuery(getNewsletterInfo);
  const newsletterInfo = useMemo(
    () => data.allContentfulFooter.edges.map(edge => edge.node),
    [data.allContentfulFooter.edges]
  );
  const filteredNewsletterInfo = useFilterLocale(newsletterInfo);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  function SuccesMessageDisplay() {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  }

  function Subscribe() {
    if (isChecked) {
      SuccesMessageDisplay();
      // setOpen(true);
      // setError(false);
    } else {
      setError(true);
    }
  }

  return (
    <div className={classes.container}>
      <p className={classes.heading}>
        {filteredNewsletterInfo[0].newsletterHeading}
      </p>
      <p className={classes.infoLine}>
        {filteredNewsletterInfo[0].newsletterInfoLine}
      </p>

      <div style={{ display: "flex", flexWrap: "nowrap" }}>
        <input
          className={classes.input}
          placeholder="Email address here.."
          type="text"
        />
        <Button
          className={classes.button}
          variant="contained"
          onClick={Subscribe}
        >
          {filteredNewsletterInfo[0].newsletterButtonLabel}
        </Button>
      </div>

      <div>
        <ErrorMessage error={error}>
          {filteredNewsletterInfo[0].newsletterErrorMessage}
        </ErrorMessage>
        <SuccessMessage open={open}>
          {filteredNewsletterInfo[0].newsletterSuccessMessage}
        </SuccessMessage>
      </div>

      <div className={classes.inputContainer}>
        <input
          style={{ marginTop: "10px" }}
          type="checkbox"
          onChange={() => {
            setIsChecked("true");
            setError(false);
          }}
        />
        <p style={{ margin: "0px 5px", fontSize: "16px" }}>
          <BodyRenderer
            json={filteredNewsletterInfo[0].newsletterCheckBoxText.json}
          />
        </p>
      </div>
    </div>
  );
}

export default Newsletter;
