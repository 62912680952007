import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import MobileStepper from '@material-ui/core/MobileStepper'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { graphql, useStaticQuery } from 'gatsby'
import useFilterLocale from 'hooks/useFilterLocale'
import React, { useCallback, useMemo } from 'react'
import SwipeableViews from 'react-swipeable-views'

import Testimonial from '../Testimonial'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    margin: '70px 0'
  },
  root: {
    padding: 0,

    '& button': {
      padding: 0
    }
  },
  steppersContainer: {},
  icon: {
    fontSize: 60,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#71cc98'
    }
  }
}))

const getTestimonials = graphql`
  {
    allContentfulTestimonial {
      edges {
        node {
          id
          node_locale
          name
          customerTestimonial
          testimonial {
            json
          }
        }
      }
    }
  }
`

function TestimonialsSlider (props) {
  const data = useStaticQuery(getTestimonials)
  const { amount } = props
  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = data.allContentfulTestimonial.edges.length
  const classes = useStyles()
  const theme = useTheme()

  const testimonials = useMemo(
    () => data.allContentfulTestimonial.edges.map(edge => edge.node),
    [data.allContentfulTestimonial.edges]
  )

  const filteredTestimponials = useFilterLocale(testimonials)

  function handleNext () {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  function handleBack () {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }
  function handleStepChange (step) {
    setActiveStep(step)
  }
  return (
    <Container className={classes.container} maxWidth="xl">
      <SwipeableViews
        enableMouseEvents
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        style={{
          overflow: 'hidden',
          paddingBottom: '10px'
        }}
        onChangeIndex={handleStepChange}
      >
        {filteredTestimponials.slice(0, amount).map((step, index) => (
          <Testimonial key={step.id} testimonial={step} />
        ))}
      </SwipeableViews>

      <MobileStepper
        activeStep={activeStep}
        backButton={(
          <Button
            className={classes.leftArrow}
            disabled={activeStep === 0}
            size="small"
            onClick={handleBack}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight className={classes.icon} />
            ) : (
              <KeyboardArrowLeft className={classes.icon} />
            )}
          </Button>
)}
        className={classes.root}
        nextButton={(
          <Button
            className={classes.rightArrow}
            disabled={activeStep === 5}
            size="small"
            onClick={handleNext}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft className={classes.icon} />
            ) : (
              <KeyboardArrowRight className={classes.icon} />
            )}
          </Button>
)}
        position="static"
        steps={filteredTestimponials.length}
        variant=""
      />
    </Container>
  )
}

export default TestimonialsSlider
