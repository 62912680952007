import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { BodyRenderer } from 'components'
import React from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    '& h2': {
      /* marginTop: "60px", */
      margin: '50px 0'
    },

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  column: {
    flex: 1,
    flexShrink: 0,
    textAlign: 'left',
    alignItems: 'space-between',
    padding: '40px 60px',

    [theme.breakpoints.down('sm')]: {
      padding: 10,
      flexDirection: 'column'
    },
    '& img': {
      /* marginTop: 40 */
    }
  },
  renderedText: {}
}))

function Columns (props) {
  const { left, right } = props
  const classes = useStyles()

  return (
    <Container maxWidth="xl">
      <Box className={classes.container}>
        <Box className={classes.column}>
          <BodyRenderer json={left} />
        </Box>
        <Box className={classes.column}>
          <BodyRenderer json={right} />
        </Box>
      </Box>
    </Container>
  )
}

export default Columns
