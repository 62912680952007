import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const getIframeData = graphql`
  {
    contentfulIframe {
      title
      url
    }
  }
`

function RequestIframe (props) {
  const data = useStaticQuery(getIframeData)
  return (
    <iframe
      height="900px"
      src={props.url}
      style={{ border: 'none' }}
      title={props.title}
      width="500px"
      width="100%"
    />
  )
}

export default RequestIframe
