import Grid from '@material-ui/core/Grid'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

const Image = styled.img`
  width: 100%;
  height: 350px;
`

const LocationContainer = styled.div`
  position: relative;
  height: 350px;
  width: 350px;
  margin: 5px;
  overflow: hidden;

  h2 {
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    text-transform: uppercase;
    color: white;
    z-index: 1500;
    pointer-events: none;
  }
`
const Cover = styled.div`
  height: 350px;
  width: 350px;
  position: absolute;
  background-color: #000000;
  top: 0;
  left: 0;
  opacity: 0.6;
  overflow: hidden;
  :hover {
    opacity: 0.1;
  }
`

function LocationCard (props) {
  const { location } = props

  return (
    <Link to={location.to}>
      <LocationContainer>
        <Img fluid={location.photo.fluid} />
        {/* <Image fluid={props.image.fluid} /> */}
        <h2>{location.locationName}</h2>
        <Cover />
      </LocationContainer>
    </Link>
  )
}

export default LocationCard
