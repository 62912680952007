import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'
import { Box } from 'components'
import Image from 'gatsby-image'
import React from 'react'

import Handcarry from '../../images/handcarry.svg'
import Luggage from '../../images/luggage.svg'
import Passenger from '../../images/passenger.svg'

const useStyles = makeStyles(theme => ({
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  vehicleTitle: {
    fontSize: '38px',
    fontWeight: 400,
    fontFamily: 'Barlow',
    margin: 5
  },
  shortVehicleDescription: {
    fontSize: '25px',
    fontWeight: 400,
    letterSpacing: '1.8px',
    margin: 10
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center'
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px'
  },
  numberValue: {
    margin: '5px',
    fontSize: '25px'
  },
  icon: {
    fontSize: '25px'
  }
}))

function VehicleCard (props) {
  const classes = useStyles()
  const { vehicle } = props
  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      <Box position="relative" width="100%">
        <Image
          fluid={vehicle.vehiclePhoto.fluid}
          imgStyle={{ objectFit: 'contain', objectPosition: 'center bottom' }}
          style={{ position: 'initial' }}
        />
      </Box>
      <Box className={classes.detailsContainer}>
        <h1 className={classes.vehicleTitle}>{vehicle.vehicleTitle}</h1>
        <p className={classes.shortVehicleDescription}>
          {vehicle.shortVehicleDescription}
        </p>
        <Box className={classes.infoContainer}>
          <Box className={classes.iconsContainer}>
            <SvgIcon className={classes.icon}>
              <Passenger />
            </SvgIcon>
            <Typography paragraph className={classes.numberValue}>
              {vehicle.numberOfPassengers}
            </Typography>
          </Box>

          <Box className={classes.iconsContainer}>
            <SvgIcon className={classes.icon}>
              <Luggage />
            </SvgIcon>
            <Typography paragraph className={classes.numberValue}>
              {vehicle.numberOfLuggagePieces}
            </Typography>
          </Box>

          <Box className={classes.iconsContainer}>
            <SvgIcon className={classes.icon}>
              <Handcarry />
            </SvgIcon>
            <Typography paragraph className={classes.numberValue}>
              {vehicle.numberOfHandcarryPieces}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default VehicleCard
