import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Container from "@material-ui/core/Container";
import SiteMap from "../SiteMap";
import Certifications from "./Certifications";
import ContactInfo from "./ContactInfo";
import LogoAndParagraph from "./LogoAndParagraph";
import Newsletter from "./Newsletter";
import SubFooter from "./SubFooter";

const getFooterInfo = graphql`
  {
    contentfulFooter {
      siteMapHeading

      copyrightText
    }
  }
`;

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    padding: "20px 0px",
    justifyContent: "center"
  },

  footer: {
    backgroundColor: "#010101",
    width: "100%",
    padding: "50px 0px"
  },

  heading: {
    color: "#fff",
    textTransform: "uppercase",
    fontSize: "20px",
    letterSpacing: "2px",
    margin: 0,
    fontWeight: "bold"
  },
  row: {
    display: "flex",

    flex: 1,
    flexShrink: 0,
    padding: "10px 20px",
    flexDirection: "column",
    flexWrap: "nowrap",
    width: 350,
    textAlign: "left",
    justifyContent: "flex-start",
    alignContent: "center",

    "& p": {
      color: "white",
      fontSize: 16,

      marginBottom: 25
    }
  }
});

function Footer() {
  const classes = useStyles();
  const data = useStaticQuery(getFooterInfo);
  return (
    <Box className={classes.footer}>
      <Container maxWidth="xl" className={classes.container}>
        <Box className={classes.row}>
          <LogoAndParagraph />
        </Box>

        <Box className={classes.row}>
          <div>
            <p className={classes.heading}>
              {data.contentfulFooter.siteMapHeading}
            </p>
            <SiteMap />
          </div>
        </Box>

        <Box className={classes.row}>
          <ContactInfo />
        </Box>

        <Box className={classes.row}>
          <Newsletter />
        </Box>

        {/* <Certifications /> */}
        <SubFooter />
      </Container>
    </Box>
  );
}

export default Footer;
