import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, useStaticQuery } from 'gatsby';
import useFilterLocale from 'hooks/useFilterLocale';
import React, { useMemo } from 'react';
import Masonry from 'react-masonry-component';

import NewsCard from '../NewsCard';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 20
  }
}))

const getNews = graphql`
  {
    allContentfulNews(sort: { order: DESC, fields: date }) {
      edges {
        node {
          node_locale
          slug
          title
          date
          newsContent {
            json
          }
          mainHeading
          coverPhoto {
            id
            fixed {
              ...GatsbyContentfulFixed
            }
          }
          author
        }
      }
    }
  }
`

function NewsTicker (props) {
  const { amount } = props
  const data = useStaticQuery(getNews)
  const classes = useStyles()

  const articles = useMemo(
    () => data.allContentfulNews.edges.map(edge => edge.node),
    [data.allContentfulNews.edges]
  )
  const filteredArticles = useFilterLocale(articles)

  return (
    <Container maxWidthXl className={classes.root}>
      <Masonry>
        {filteredArticles.slice(0, amount).map(article => (
          <NewsCard news={article} />
        ))}
      </Masonry>
    </Container>
  )
}

export default NewsTicker
