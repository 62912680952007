import { graphql, useStaticQuery } from "gatsby";
import useFilterLocale from "hooks/useFilterLocale";
import { useMemo } from "react";

const getNavigationLinks = graphql`
  {
    allContentfulLayout(filter: { slug: { eq: "default" } }) {
      edges {
        node {
          node_locale
          slug
          siteMapLinks {
            ... on ContentfulPage {
              id
              title
              slug
              node_locale
            }
          }
        }
      }
    }
  }
`;

function useSiteMapLinks() {
  const data = useStaticQuery(getNavigationLinks);
  const nodes = useMemo(
    () =>
      data.allContentfulLayout.edges.reduce(
        (acc, edge) => [...acc, ...edge.node.siteMapLinks],
        []
      ),
    [data.allContentfulLayout.edges]
  );

  const filteredLinks = useFilterLocale(nodes);

  return filteredLinks;
}

export default useSiteMapLinks;
