import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { graphql, useStaticQuery } from "gatsby";
import useFilterLocale from "hooks/useFilterLocale";
import React, { useMemo } from "react";
import styled from "styled-components";

import LocationCard from "../LocationCard";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    paddingBottom: 40
  }
});

const getLocations = graphql`
  {
    allContentfulLocation {
      edges {
        node {
          locationName
          id
          node_locale
          slug
          subline
          description {
            json
          }
          photo {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyContentfulFluid
            }
            title
          }
        }
      }
    }
  }
`;

function LocationsTicker(props) {
  const classes = useStyles();
  const { amount } = props;
  const data = useStaticQuery(getLocations);
  const locations = useMemo(
    () => data.allContentfulLocation.edges.map(edge => edge.node),
    [data.allContentfulLocation.edges]
  );
  const filteredLocations = useFilterLocale(locations);

  return (
    <Container className={classes.container} maxWidth="lg">
      {filteredLocations.slice(0, amount).map(location => (
        <LocationCard key={location.id} location={location} />
      ))}
    </Container>
  );
}

export default LocationsTicker;
