import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

const Image = styled(Img)`
  width: 100%;
  height: 100%;
`

const Slide = styled.div`
  position: relative;
  height: 450px;
  width: 350px;
  margin: 0;

  background-color: #000000;
  top: 0;
  left: 0;
  opacity: 1;
  overflow: hidden;

  h2 {
    position: absolute;
    top: 30px;
    left: 25px;
    color: white;
    font-family: Libre Baskerville;
    text-shadow: 3px 3px 6px #666;
    font-size: 31px;
    font-weight: bold;
    text-align: left;
    line-height: 35px;
  }
  .index {
    position: absolute;

    top: 10px;
    left: 25px;
    color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 45px;
    margin: 0;
    font-family: Barlow;
    text-shadow: 3px 3px 6px #666;
  }
`

const SlideCover = styled.div`
  height: 450px;
  width: 350px;
  position: absolute;
  background-color: #000000;
  top: 0;
  left: 0;
  opacity: 0.4;
  overflow: hidden;
  opacity: ${props => (props.noOverlay ? 0 : 0.4)};
`

const ServiceContainer = styled.div`
  position: relative;
  width: 460px;
  height: 300px;
  margin: ${({ noMargin }) => (noMargin ? 0 : 5)}px;

  @media (max-width: 960px) {
    height: 300px;
    width: 350px;
  }

  h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    text-transform: uppercase;
    color: white;
    z-index: 1500;
    margin: 0;
    pointer-events: none;
  }
`
const Cover = styled.div`
  width: 460px;
  height: 300px;
  position: absolute;
  background-color: #000000;
  top: 0;
  left: 0;
  opacity: ${props => (props.noOverlay ? 0 : 0.6)};
  transition: opacity 300ms;
  :hover {
    opacity: 0.1;
  }
  @media (max-width: 960px) {
    height: 300px;
    width: 350px;
  }
`

export const ServicePreviewCard = (props) => {
  const { service, noMargin, noOverlay } = props
  return (
    <Link to={service.to}>
      <ServiceContainer noMargin={noMargin}>
        {/* <Image src={service.photo.file.url} /> */}
        <Image
          alt={service.photo.title}
          fluid={service.photo.fluid}
          objectFit="contain"
        />
        <h3>{service.serviceName}</h3>
        <Cover noOverlay={noOverlay} />
      </ServiceContainer>
    </Link>
  )
}

export const ServicesSliderCard = (props) => {
  const {
    index, service, noMargin, noOverlay
  } = props

  return (
    <Link to={service.to}>
      <Slide noMargin={noMargin}>
        <Image
          alt={service.photo.title}
          fluid={service.photo.fluid}
          objectFit="contain"
        />
        {/* <Image fluid={props.image.fluid} /> */}

        <p className="index">{`0${index}`}</p>
        <h2>{service.serviceName}</h2>
        <SlideCover noOverlay={noOverlay} />
      </Slide>
    </Link>
  )
}
