import { usePageContext } from 'components/PageContext'
import { useMemo } from 'react'

function useFilterLocale (nodes) {
  const pageContext = usePageContext()

  return useMemo(
    () =>
      nodes
        .filter(node => node.node_locale === (pageContext.locale || 'en'))
        .map(node => ({
          ...node,
          to:
            node.slug === 'home'
              ? `/${node.node_locale}/`
              : `/${node.node_locale}/${node.slug}/`
        })),
    [nodes, pageContext.locale]
  )
}

export default useFilterLocale
