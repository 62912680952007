import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { BodyRenderer } from 'components'
import React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    // padding: "30px 0px",

    [theme.breakpoints.down('md')]: {},
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },
    '& h1': {
      /* [theme.breakpoints.up("lg")]: {
        margin: "0px 50px"
      }, */
      textAlign: 'center',
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        fontSize: 28,
        lineHeight: '40px'
      }
    },
    '& h2': {
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
        lineHeight: '30px',
        fontWeight: '600',

        margin: '0 auto'
      }
    }
  },
  centeredText: {
    textAlign: 'center'
  }
}))

function OneColumnText (props) {
  const { content, centered } = props
  const classes = useStyles()
  // NOTE: Centered was incorrectly named; centered = false is centered text
  const textClasses =
    !centered || centered.en === false || centered.de === false
      ? [classes.root, classes.centeredText].join(' ')
      : classes.root

  return (
    <Container className={classes.root} maxWidth="xl">
      {/* <Grid
        container
        className={
          textClasses
          //!centered ? `${classes.root} ${classes.centeredText}` : classes.root
        }
        justify="center"
      > */}
      {/* <Grid item> */}
      <BodyRenderer json={content} />
      {/* </Grid> */}
      {/* </Grid> */}
    </Container>
  )
}

export default OneColumnText
