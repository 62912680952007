import Container from '@material-ui/core/Container'
import React from 'react'
import styled from 'styled-components'

// import LanguageSwitcher from '../LanguageSwitcher'
import Logo from '../Logo'
import Navigation from '../Navigation'

const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  /*   padding-bottom: 30px; */
  background-color: black;
  justify-content: space-between;
  @media (max-width: 1100px) {
    justify-content: space-between;
  }
`
const NavWrapper = styled.div`
  background-color: black;
  position: sticky;
  top: 0;
  z-index: 99999;
`

function Header () {
  return (
    <NavWrapper>
      <HeaderContainer maxWidth="xl">
        <Logo />
        <Navigation />
        {/* <LanguageSwitcher /> */}
      </HeaderContainer>
    </NavWrapper>
  )
}

export default Header
