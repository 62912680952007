import { makeStyles } from "@material-ui/core/styles";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import styled from "styled-components";
import AribaDiscovery from "../../images/a2b-ariba-discovery.jpg";

const Image = styled(Img)`
  margin: 10px;
`;

const Photo = styled.img`
  margin: 10px;
`;

const getCertifications = graphql`
  {
    contentfulLayout {
      certificationPhotos {
        fixed(height: 60) {
          ...GatsbyContentfulFixed
        }
      }
    }
  }
`;

const useStyles = makeStyles({
  imagesContainer: {
    display: "flex",
    backgroundColor: "#010101",
    paddingBottom: 25,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center"
  }
});

function Certifications() {
  const classes = useStyles();
  const data = useStaticQuery(getCertifications);
  return (
    <div className={classes.imagesContainer}>
      {data.contentfulLayout.certificationPhotos.map(photo => (
        <Image fixed={photo.fixed} key={photo.fixed.src} />
      ))}
      <a href="http://discovery.ariba.com/profile/AN01051899639">
        <Photo
          src={AribaDiscovery}
          alt="Profil von A2B Business Service GmbH in Ariba Discovery anzeigen"
        />
      </a>
    </div>
  );
}

export default Certifications;
