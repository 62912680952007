import { Box, VehicleCard } from "components";
import React from "react";

const Slide = React.memo(props => {
  const { currentIndex, activeIndex, vehicle } = props;
  const isActive = currentIndex === activeIndex;

  return (
    <Box
      css={`
        opacity: ${isActive ? 1 : 0.6};
        transition: opacity 300ms;

        ${({ theme }) => theme.transitions.easing.easeIn};
      `}
    >
      <VehicleCard vehicle={vehicle} />
    </Box>
  );
});

export default Slide;
