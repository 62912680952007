import { BodyRenderer } from "components";
import React from "react";
import styled from "styled-components";
import Container from "@material-ui/core/Container";

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  text-align: center;
  color: black;
  position: relative;
  overflow: hidden;
`;
const Content = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 60%;
  padding: 0px 15px;

  @media (max-width: 1100px) {
  }
  &:before {
    content: "“";
    position: absolute;
    margin-top: 60px;
    margin-right: 25px;
    left: 0;
    color: #71cc98;
    font-family: "Baskerville, semi-bold";

    font-size: 200px;
    font-weight: 400;
  }
  &:after {
    content: "”";
    position: absolute;
    right: 0;
    bottom: -33px;

    color: #71cc98;
    font-family: "Baskerville, semi-bold";
    font-size: 200px;
    font-weight: 400;
  }
  .name {
    font-style: italic;
  }
`;

const CustomerTestimonial = styled.p`
  font-size: 25px;
  font-weight: 400;
`;

const Quote = styled.div`
  position: absolute;
  font-size: 167px;
`;

// font-size: 167px;
//     font-family: Baskerville;
//     font-weight: 600;

function Testimonial(props) {
  const { testimonial } = props;

  return (
    <Slide>
      <Content>
        {/* <BodyRenderer json={testimonial.testimonial.json} /> */}

        <CustomerTestimonial className="testimonial">
          {testimonial.customerTestimonial}
        </CustomerTestimonial>

        <p className="name">{testimonial.name}</p>
      </Content>
    </Slide>
  );
}

export default Testimonial;
