import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { usePageContext } from 'components/PageContext'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import {
  compose, equals, filter, pick, prop
} from 'ramda'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const useStyles = makeStyles(theme => ({
  mainContainer: {
    backgroundColor: '#1b1e27',
    width: '100%',
    padding: '20px 0px',
    zIndex: '-1'
    /*  marginTop: "-30px" */
  },
  leftContainer: {
    [theme.breakpoints.down('sm')]: {
      flexGrow: '0',

      justifyContent: 'center'
    }
  },
  textContainer: {
    width: '100%'
  },
  mainHeading: {
    color: '#fff',

    textAlign: 'left',
    fontSize: 60,
    fontWeight: 600,
    letterSpacing: '1px',
    width: '100%',
    lineHeight: '65px',
    [theme.breakpoints.down('md')]: {
      lineHeight: '75px',
      width: '70%'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '35px',
      lineHeight: '50px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '40px',
      lineHeight: '50px'
    }
  },
  sublineContainer: {
    margin: '50px 0px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,

      position: 'relative'
    },
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      marginTop: 0
    }
  },
  subHeading: {
    color: '#fff',
    marginRight: 10,
    marginTop: 0,
    fontFamily: 'Barlow',
    fontSize: '20px',
    flexShrink: '0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px'
    }
  },
  bigImage: {
    width: '100%'
  },
  smallImage: {
    height: '71px',
    width: '63px',
    position: 'absolute !important',
    top: '4%',
    right: 20,
    zIndex: '1998',
    [theme.breakpoints.down('sm')]: {
      left: '75%',
      top: '0.5%',
      width: '60px',
      height: '60px'
    }
  },
  avatar: {
    backgroundColor: '#71cc98',
    height: 20,
    width: 20,
    marginTop: 5
  },
  flexContainer: {
    position: 'relative',
    display: 'flex',

    flexWrap: 'wrap'
  },
  leftContainer: {
    flex: 1,
    position: 'relative',
    // paddingLeft: 50,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0
    }
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    // paddingRight: 50,
    alignItems: 'center',
    flexShrink: 0,
    position: 'relative'
  }
}))

const SmallImage = styled(Img)`
  img {
    object-fit: contain !important;
  }
`

// Query for all site Hero Components
const allHeroComponents = graphql`
  query {
    allContentfulHeroComponent {
      edges {
        node {
          heading
          subheading
          node_locale
          secondaryImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
            title
          }
          mainImage {
            fluid(maxWidth: 800, quality: 70) {
              ...GatsbyContentfulFluid_withWebp
            }
            title
          }
        }
      }
    }
  }
`

// Filter global hero component query for this component
const useHeroContent = (heading, subheading) => {
  const { allContentfulHeroComponent } = useStaticQuery(allHeroComponents)
  const { locale } = usePageContext()
  return useMemo(
    () =>
      filter(
        compose(
          equals({ heading, subheading, node_locale: locale }),
          pick(['heading', 'subheading', 'node_locale']),
          prop('node')
        ),
        allContentfulHeroComponent?.edges
      )[0]?.node || {},
    [allContentfulHeroComponent, heading, locale, subheading]
  )
}

function HeroComponent ({ heading, subheading, ...props }) {
  const classes = useStyles()
  const { mainImage, secondaryImage } = useHeroContent(heading, subheading)

  return (
    <Box className={classes.mainContainer}>
      <Container maxWidth="xl">
        <Box className={classes.flexContainer}>
          <Grid container className={classes.flexContainer}>
            <Grid item className={classes.leftContainer} md={6} sm={12}>
              {secondaryImage && (
                <SmallImage
                  alt={secondaryImage.title}
                  className={classes.smallImage}
                  fluid={secondaryImage.fluid}
                />
              )}
              <Box className={classes.textContainer}>
                <Typography className={classes.mainHeading} variant="h1">
                  {heading}
                </Typography>
                <Box className={classes.sublineContainer}>
                  <Typography parahraph className={classes.subHeading}>
                    {subheading}
                  </Typography>
                  <Link to="/de/buchen">
                    <Avatar className={classes.avatar}>
                      <KeyboardArrowRight />
                    </Avatar>
                  </Link>
                </Box>
              </Box>
            </Grid>

            <Grid item md={6} xs={12}>
              <Box className={classes.imageContainer}>
                {/* <img
                  className={classes.bigImage}
                  src={mainImage.file.url}
                ></img> */}
                <Img
                  alt={mainImage.title}
                  className={classes.bigImage}
                  fluid={mainImage.fluid}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default HeroComponent
