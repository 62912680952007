import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 20,
    display: 'flex',
    justifyContent: 'center'
  },
  infoContainer: {
    backgroundColor: '#ebebeb',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    fontSize: '20px'
  },
  infoLine: { letterSpacing: '2px' },
  iconAndTextContainer: {
    display: 'flex',
    margin: 5
  },
  icon: {
    color: theme.palette.primary.main
  }
}))

function ContactInfo (props) {
  const classes = useStyles()
  const { contactInfoLine, heading } = props
  // console.log(contactInfoLine, heading)
  return (
    <Grid container className={classes.container}>
      <Grid item className={classes.infoContainer} md={6} sm={12}>
        <div>
          <p>{heading}</p>
          {contactInfoLine.map(infoLine => (
            <div className={classes.iconAndTextContainer}>
              <KeyboardArrowRight className={classes.icon} />
              <Box className={classes.infoLine}>{infoLine}</Box>
            </div>
          ))}
        </div>
      </Grid>
    </Grid>
  )
}

export default ContactInfo
